<template>
    <div class="p-relative">
        <v-tabs
            v-model="searchType"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab>{{ $t("Поиск_по_ИИН") }}</v-tab>
            <v-tab>{{ $t("Поиск_по_ФИО") }}</v-tab>
            <v-tab>{{ $t("Поиск_по_адресу") }}</v-tab>

        </v-tabs>
        <v-tabs-items
            v-model="searchType"
            class="tab-content-panel"
        >
            <v-tab-item>
                <!--Форма поиска по ИИН-->
                <v-form class="wrapperForm" ref="formIIN" lazy-validation>
                    <v-row dense>
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <v-row class="two-columns-field-in-one-box" no-gutters>                                    
                                        <v-col cols="12" sm="12" md="6">  
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("ИИН") }}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <v-text-field
                                                        v-model="filterIin.iin"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        @keypress="filterNumberField()"
                                                    >
                                                    </v-text-field>  
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">  
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Территориальная_единица") }}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <v-select
                                                        v-model="filterIin.region"
                                                        :items="regionsIin"
                                                        item-text="name"
                                                        item-value="id"
                                                        hide-details
                                                        outlined
                                                        dense
                                                        append-icon="fas fa-chevron-down"
                                                        :placeholder="$t('Территориальная_единица')"
                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                        @click:clear="filterIin.region = null"
                                                        clearable
                                                    >
                                                        <template v-slot:prepend-item>
                                                            <v-list-item>
                                                                <v-list-item-content>
                                                                    <v-text-field
                                                                        v-model="regionsSearchIin"
                                                                        :placeholder="$t('Поиск')"
                                                                        class="mb-2 autocomplete-search"
                                                                        hide-details
                                                                        dense
                                                                        clearable
                                                                        autofocus
                                                                    >
                                                                    </v-text-field>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>

                                    <v-btn
                                        color="blue-grey"
                                        text
                                        depressed
                                        dense
                                        @click="clearFilterIin"
                                    >
                                        {{ $t("Сбросить") }}
                                    </v-btn>

                                    <v-btn
                                        color="cyan"
                                        text
                                        depressed
                                        dense
                                        @click="setFilter"
                                    >
                                        {{ $t("Поиск") }}
                                    </v-btn>

                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-tab-item>

            <v-tab-item>
                <!--Форма поиска ФИО-->
                <v-form class="wrapperForm" ref="formFIO" lazy-validation>
                    <v-row dense>
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <v-row class="two-columns-field-in-one-box" no-gutters>                                    
                                        <v-col cols="12" sm="12" md="6">

                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Фамилия") }}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <v-text-field
                                                        v-model="filterFio.surname"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                    >
                                                    </v-text-field>  
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Имя") }}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <v-text-field
                                                        v-model="filterFio.name"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                    >
                                                    </v-text-field>  
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Отчество") }}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <v-text-field
                                                        v-model="filterFio.patronymic"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                    >
                                                    </v-text-field>  
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Дата_рождения") }}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <v-menu 
                                                        v-model="dateMenuVisibleDateBirthFrom"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                :value="filterFio.birthDateFrom | formattedDate"
                                                                @keydown="onBirthDateFromKeyDown"
                                                                @paste="onPasteBirthDateFrom"
                                                                :readonly="filterFio.birthDateFrom != null"
                                                                prepend-icon="mdi-calendar"
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                hide-details
                                                                required
                                                                outlined
                                                                dense
                                                                class="datepick-input"
                                                                :placeholder="$t('дд.мм.гггг')"
                                                                clearable
                                                                @click:clear="filterFio.birthDateFrom = null"
                                                            >
                                                            </v-text-field>
                                                        </template>

                                                        <div class="d-flex">
                                                            <v-date-picker 
                                                                v-model="filterFio.birthDateFrom"
                                                                @input="dateMenuVisibleDateBirthFrom = false"
                                                                color="teal"
                                                                :first-day-of-week="1"
                                                            >
                                                                <v-btn 
                                                                    color="teal" 
                                                                    @click="showMonthSelectorBirthFrom = true, showYearSelectorBirthFrom = false"
                                                                    small
                                                                    dark
                                                                    depressed
                                                                >
                                                                    {{ $t("Выбрать_месяц") }}
                                                                </v-btn>
                                                                <v-spacer></v-spacer>
                                                                <v-btn 
                                                                    color="teal" 
                                                                    @click="showYearSelectorBirthFrom = true, showMonthSelectorBirthFrom = false"
                                                                    small
                                                                    dark
                                                                    depressed
                                                                >
                                                                    {{ $t("Выбрать_год") }}
                                                                </v-btn>
                                                            </v-date-picker>

                                                            <v-card
                                                                v-if="showYearSelectorBirthFrom"
                                                                max-width="400px"
                                                                max-height="414px"
                                                                class="datepick-scrollable-content"
                                                                flat
                                                            >
                                                                <v-card-text>
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <v-btn 
                                                                                text 
                                                                                small 
                                                                                color="teal" 
                                                                                @click="showYearSelectorBirthFrom = false"
                                                                            >
                                                                                <v-icon left>far fa-arrow-from-right</v-icon>
                                                                                {{ $t("Назад") }}
                                                                            </v-btn>    
                                                                        </v-col> 
                                                                        <v-col
                                                                            v-for="year in years"
                                                                            :key="year"
                                                                            cols="3"
                                                                            class="d-flex justify-center"
                                                                        >
                                                                            <v-btn
                                                                                text
                                                                                small
                                                                                rounded
                                                                                @click="selectYearBirthFrom(year)"
                                                                            >
                                                                                {{ year }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                            </v-card>

                                                            <v-card
                                                                v-else-if="showMonthSelectorBirthFrom"
                                                                max-width="400px"
                                                                max-height="414px"
                                                                class="datepick-scrollable-content"
                                                                flat
                                                            >
                                                                <v-card-text>
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <v-btn 
                                                                                text 
                                                                                small 
                                                                                color="teal" 
                                                                                @click="showMonthSelectorBirthFrom = false"
                                                                            >
                                                                                <v-icon left>far fa-arrow-from-right</v-icon>
                                                                                {{ $t("Назад") }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col
                                                                            v-for="(month, index) in months"
                                                                            :key="index"
                                                                            cols="3"
                                                                            class="d-flex justify-center"
                                                                        >
                                                                            <v-btn
                                                                                text
                                                                                small
                                                                                rounded
                                                                                @click="selectMonthBirthFrom(index)"
                                                                            >
                                                                                {{ month }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                            </v-card>
                                                        </div>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>

                                        </v-col>

                                        <v-col cols="12" sm="12" md="6">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Территориальная_единица") }}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <v-select
                                                        v-model="filterFio.region"
                                                        :items="regionsFio"
                                                        item-text="name"
                                                        item-value="id"
                                                        hide-details
                                                        outlined
                                                        dense
                                                        append-icon="fas fa-chevron-down"
                                                        :placeholder="$t('Территориальная_единица')"
                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                        @click:clear="filterFio.region = null"
                                                        clearable
                                                    >
                                                        <template v-slot:prepend-item>
                                                            <v-list-item>
                                                                <v-list-item-content>
                                                                    <v-text-field
                                                                        v-model="regionsSearchFio"
                                                                        :placeholder="$t('Поиск')"
                                                                        class="mb-2 autocomplete-search"
                                                                        hide-details
                                                                        dense
                                                                        clearable
                                                                        autofocus
                                                                    >
                                                                    </v-text-field>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>

                                    <v-btn
                                        color="blue-grey"
                                        text
                                        depressed
                                        dense
                                        @click="clearFilterFio"
                                    >
                                        {{ $t("Сбросить") }}
                                    </v-btn>

                                    <v-btn
                                        color="cyan"
                                        text
                                        depressed
                                        dense
                                        @click="setFilter"
                                    >
                                        {{ $t("Поиск") }}
                                    </v-btn>

                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-tab-item>
            
            <v-tab-item>
                <!--Форма поиска адресу-->
                <v-form class="wrapperForm" ref="formAddress" lazy-validation>
                    <v-row dense>
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <v-row class="two-columns-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="6">
                                            
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Территориальная_единица") }}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <v-select
                                                        v-model="filterAddress.region"
                                                        :items="regionsAddress"
                                                        item-text="name"
                                                        item-value="id"
                                                        hide-details
                                                        outlined
                                                        dense
                                                        append-icon="fas fa-chevron-down"
                                                        :placeholder="$t('Территориальная_единица')"
                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                    >
                                                        <template v-slot:prepend-item>
                                                            <v-list-item>
                                                                <v-list-item-content>
                                                                    <v-text-field
                                                                        v-model="regionsSearchAddress"
                                                                        :placeholder="$t('Поиск')"
                                                                        class="mb-2 autocomplete-search"
                                                                        hide-details
                                                                        dense
                                                                        clearable
                                                                        autofocus
                                                                    >
                                                                    </v-text-field>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>

                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Населенный_пункт") }}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <v-select
                                                        v-model="filterAddress.city"
                                                        :items="filterAddressCities"
                                                        item-text="name"
                                                        item-value="id"
                                                        hide-details
                                                        outlined
                                                        dense
                                                        append-icon="fas fa-chevron-down"
                                                        :placeholder="$t('Населенный_пункт')"
                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                    >
                                                        <template v-slot:prepend-item>
                                                            <v-list-item>
                                                                <v-list-item-content>
                                                                    <v-text-field
                                                                        :value="citySearchAddress"
                                                                        @input="debounceInput({ target: 'citySearchAddress', value: $event })"
                                                                        :placeholder="$t('Поиск')"
                                                                        class="mb-2 autocomplete-search"
                                                                        hide-details
                                                                        dense
                                                                        clearable
                                                                        autofocus
                                                                    >
                                                                    </v-text-field>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>

                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Улица") }}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <v-select
                                                        v-model="filterAddress.street"
                                                        :items="filterAddressStreets"
                                                        item-text="name"
                                                        item-value="id"
                                                        hide-details
                                                        outlined
                                                        dense
                                                        append-icon="fas fa-chevron-down"
                                                        :placeholder="$t('Улица')"
                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                    >
                                                        <template v-slot:prepend-item>
                                                            <v-list-item>
                                                                <v-list-item-content>
                                                                    <v-text-field
                                                                        :value="streetSearchAddress"
                                                                        @input="debounceInput({ target: 'streetSearchAddress', value: $event })"
                                                                        :placeholder="$t('Поиск')"
                                                                        class="mb-2 autocomplete-search"
                                                                        hide-details
                                                                        dense
                                                                        clearable
                                                                        autofocus
                                                                    >
                                                                    </v-text-field>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>

                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Дом") }}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <v-select
                                                        v-model="filterAddress.house"
                                                        :items="filterAddressHouses"
                                                        item-text="name"
                                                        item-value="id"
                                                        hide-details
                                                        outlined
                                                        dense
                                                        append-icon="fas fa-chevron-down"
                                                        :placeholder="$t('Дом')"
                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                    >
                                                        <template v-slot:prepend-item>
                                                            <v-list-item>
                                                                <v-list-item-content>
                                                                    <v-text-field
                                                                        :value="houseSearchAddress"
                                                                        @input="debounceInput({ target: 'houseSearchAddress', value: $event })"
                                                                        :placeholder="$t('Поиск')"
                                                                        class="mb-2 autocomplete-search"
                                                                        hide-details
                                                                        dense
                                                                        clearable
                                                                        autofocus
                                                                    >
                                                                    </v-text-field>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>

                                        </v-col>

                                        <v-col cols="12" sm="12" md="6">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Код_АР") }}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <v-text-field
                                                        :value="filterAddress.house"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        :placeholder="$t('Код_АР')"
                                                        :readonly="true"
                                                    >
                                                    </v-text-field>  
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>

                                    <v-btn
                                        color="blue-grey"
                                        text
                                        depressed
                                        dense
                                        @click="clearFilterAddress"
                                    >
                                        {{ $t("Сбросить") }}
                                    </v-btn>

                                    <v-btn
                                        color="cyan"
                                        text
                                        depressed
                                        dense
                                        @click="setFilter"
                                        :disabled="isAddressSearchDisabled"
                                    >
                                        {{ $t("Поиск") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-tab-item>

        </v-tabs-items>

        <v-data-table
            class="elevation-1 customization-list-table pagination-list-table sticky-table"
            :headers="headers"
            :options.sync="options"
            :page.sync="options.page"
            :items="entities"
            :server-items-length="total"
            :footer-props="{ itemsPerPageOptions:[25, 50, 100] }"
            @page-count="paginationPageCount = $event"
            @dblclick:row="(event, data) => $router.push({ name: 'Voter', params: { id: data.item.hash } })"
            :loading="loading"
        >

            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

            <template v-slot:[`item.birthDate`]="{ item }">
                {{ $moment(item.birthDate).format('DD.MM.yyyy') }}
            </template>

            <template v-slot:[`item.electionPart`]="{ item }">
                {{ item.electionPart ? item.electionPart : $t('Нет_данных') }}
            </template>

            <template v-slot:[`footer.prepend`]>
                <v-pagination
                    v-model="options.page"
                    :length="paginationPageCount"
                    :total-visible="$listPaginationTotal"
                    color="teal"
                ></v-pagination>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import i18n from '@/i18n';
import sys from '@/services/system';
import { httpAPI } from "@/api/httpAPI";
import _ from 'lodash';

export default {
    name: "LocalSearch",
    asyncComputed: {
        regions: {
            async get() {
                let regionsResponse = await httpAPI.get(`saylau/adressregister/select/regions`);

                if (regionsResponse)
                    return regionsResponse.data.map(x => x);

                return [];
            },
            default: []
        },
        filterAddressCities: {
            async get() {
                if (!this.filterAddress.region)
                    return [];

                let citiesResponse = await httpAPI.get(`saylau/adressregister/select/cities/${this.filterAddress.region}`);

                if (citiesResponse) {
                    if (this.citySearchAddress)
                        return citiesResponse.data.map(x => x).filter(i => sys.checkSearch(i.name, this.citySearchAddress));
                    else
                        return citiesResponse.data.map(x => x);
                }
                    

                return [];
            },
            default: [],
            watch: [ 'filterAddress.region', 'citySearchAddress' ]
        },
        filterAddressStreets: {
            async get() {
                if (!this.filterAddress.region || !this.filterAddress.city)
                    return [];

                let streetsResponse = await httpAPI.get(`saylau/adressregister/select/streets/${this.filterAddress.region}/${this.filterAddress.city}`);

                if (streetsResponse) {
                    if (this.streetSearchAddress)
                        return streetsResponse.data.map(x => x).filter(i => sys.checkSearch(i.name, this.streetSearchAddress));
                    else
                        return streetsResponse.data.map(x => x);
                }

                return [];
            },
            default: [],
            watch: [ 'filterAddress.city', 'streetSearchAddress' ]
        },
        filterAddressHouses: {
            async get() {
                if (!this.filterAddress.region || !this.filterAddress.city || !this.filterAddress.street)
                    return [];

                let housesResponse = await httpAPI.get(`saylau/adressregister/select/houses/${this.filterAddress.region}/${this.filterAddress.city}/${this.filterAddress.street}`);

                if (housesResponse) {
                    if (this.houseSearchAddress)
                        return housesResponse.data.map(x => x).filter(i => sys.checkSearch(i.name, this.houseSearchAddress));
                    else
                        return housesResponse.data.map(x => x);
                }

                return [];
            },
            default: [],
            watch: [ 'filterAddress.street', 'houseSearchAddress' ]
        }
    },
    computed: {
        headers() {
            return [
                { text: i18n.t("ИИН"), value: 'hash', width: '110px', sortable: false },
                { text: i18n.t("Фамилия_Имя_Отчество"), value: 'fullName', width: '350px' },
                { text: i18n.t("Дата_рождения"), value: 'birthDate', width: '150px', sortable: false },
                { text: i18n.t("№_документа"), value: 'documentNumber', width: '150px', sortable: false },
                { text: i18n.t("Территориальная_единица"), value: 'region', sortable: false },
                { text: i18n.t("Адрес"), value: 'address' },
                { text: i18n.t("№_участка"), value: 'electionPart', sortable: false },
            ];
        },
        options: {
            get: function() {
                return this.$store.getters['saylau/lists/localSearch/getTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('saylau/lists/localSearch/SET_TABLE_OPTION', newValue);
            }
        },
        searchType: {
            get: function() {
                return this.$store.getters['saylau/lists/localSearch/getSearchType'];
            },
            set: function(newValue) {
                this.entities = [];
                this.total = 0;
                this.$store.commit('saylau/lists/localSearch/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/localSearch/SET_SEARCH_TYPE', newValue);
            }
        },
        filterFio: {
            get: function() {
                return this.$store.getters['saylau/lists/localSearch/getFilterFio'];
            },
            set: function(newValue) {
                this.$store.commit('saylau/lists/localSearch/SET_FILTER_FIO', newValue);
            }
        },
        filterIin: {
            get: function() {
                return this.$store.getters['saylau/lists/localSearch/getFilterIin'];
            },
            set: function(newValue) {
                this.$store.commit('saylau/lists/localSearch/SET_FILTER_IIN', newValue);
            }
        },
        filterAddress: {
            get: function() {
                return this.$store.getters['saylau/lists/localSearch/getFilterAddress'];
            },
            set: function(newValue) {
                this.$store.commit('saylau/lists/localSearch/SET_FILTER_ADDRESS', newValue);
            }
        },
        locale() {
            return this.$i18n.locale;
        },
        regionsFio() {
            if (this.regionsSearchFio)
                return this.regions.map(x => x).filter(i => sys.checkSearch(i.name, this.regionsSearchFio));
            else
                return this.regions.map(x => x);
        },
        regionsIin() {
            if (this.regionsSearchIin)
                return this.regions.map(x => x).filter(i => sys.checkSearch(i.name, this.regionsSearchIin));
            else
                return this.regions.map(x => x);
        },
        regionsAddress() {
            if (this.regionsSearchAddress)
                return this.regions.map(x => x).filter(i => sys.checkSearch(i.name, this.regionsSearchAddress));
            else
                return this.regions.map(x => x);
        },
        isAddressSearchDisabled() {
            return this.filterAddress.region == null ||
                   this.filterAddress.city == null ||
                   this.filterAddress.street == null ||
                   this.filterAddress.house == null;
        }
    },
    data() {
        return {
            dateMenuVisibleDateBirthFrom: false,

            showYearSelectorBirthFrom: false,
            showMonthSelectorBirthFrom: false,
            years: Array.from({ length: 125 }, (_, i) => new Date().getFullYear() - i),
            months: [
                i18n.t('Январь'), i18n.t('Февраль'), i18n.t('Март'), i18n.t('Апрель'), i18n.t('Май'), i18n.t('Июнь'), 
                i18n.t('Июль'), i18n.t('Август'), i18n.t('Сентябрь'), i18n.t('Октябрь'), i18n.t('Ноябрь'), i18n.t('Декабрь')
            ],

            regionsSearchFio: null,
            regionsSearchIin: null,
            regionsSearchAddress: null,
            citySearchAddress: null,
            streetSearchAddress: null,
            houseSearchAddress: null,

            paginationPageCount: 0,
            loading: false,

            total: 0,
            entities: [],

            isCtrlPressed: false
        }
    },
    methods: {
        clearFilterFio() {
            let region = this.regions.length == 1 ? this.regions[0].id : null;

            this.filterFio = {
                surname: "",
                name: "",
                patronymic: "",
                birthDateFrom: null,
                region: region
            };
            
            this.entities = [];
            this.total = 0;
        },
        clearFilterIin() {
            let region = this.regions.length == 1 ? this.regions[0].id : null;

            this.filterIin = {
                iin: "",
                region: region
            };
            this.entities = [];
            this.total = 0;
        },
        clearFilterAddress() {
            let region = this.regions.length == 1 ? this.regions[0].id : null;
            
            this.filterAddress = {
                region: region,
                city: null,
                street: null,
                house: null
            };

            this.entities = [];
            this.total = 0;
        },
        async setFilter() {
            await this.loadVoters();
        },
        async loadVoters() {
            this.loading = true;
            this.entities = [];
            this.total = 0;
            
            let response = await httpAPI.get(`saylau/voters/search?filter=${this.getTableFilter()}`);

            if (response) {
                this.entities = response.data.items;
                this.total = response.data.count;
                if (response.data.count == 0){
                    this.$notify.warning(this.$t("В_системе_отсутствуют_сведения_по_указанным_критериям_поиска"));
                }
            }

            this.loading = false;
        },
        getTableFilter() {
            let { page, itemsPerPage, sortBy, sortDesc } = this.options;

            let orderBy = sortBy.length <= 0 ? null : sortBy[0];
            let descending = sortDesc.length <= 0 ? null : sortDesc[0];            

            let filter = {
                page,
                itemsPerPage,
                orderBy,
                descending,
                searchType: this.searchType
            };

            switch (this.searchType)
            {
                case 1: {
                    let { region, ...extendedFilter } = this.filterFio;
                    filter['extendedFilter'] = extendedFilter;
                    filter['region'] = region;
                    break;
                }

                case 2: {
                    let { region, house } = this.filterAddress;
                    filter['extendedFilter'] = { rka: house };
                    filter['region'] = region;
                    break;
                }

                default: {
                    let { region, ...extendedFilter } = this.filterIin;
                    filter['extendedFilter'] = extendedFilter;
                    filter['region'] = region;
                    break;
                }
            }

            return JSON.stringify(filter);
        },
        filterNumberField(evt){
            return sys.filterNumberField(evt, 12);
        },
        selectYearBirthFrom(year) {
            const date = new Date(this.filterFio.birthDateFrom || new Date());
            date.setFullYear(year);
            this.filterFio.birthDateFrom = date.toISOString().substring(0, 10);
            this.showYearSelectorBirthFrom = false;
        },
        selectMonthBirthFrom(monthIndex) {
            const date = new Date(this.filterFio.birthDateFrom || new Date());
            date.setMonth(monthIndex);
            this.filterFio.birthDateFrom = date.toISOString().substring(0, 10);
            this.showMonthSelectorBirthFrom = false;
        },
        debounceInput: _.debounce(function(v) {
            this[v.target] = v.value; 
        }, 500),
        onBirthDateFromKeyDown(e) {
            if (this.isCtrlPressed && e.keyCode == 86) {
                this.isCtrlPressed = false;
                return;
            }

            if (e.keyCode == 17) {
                this.isCtrlPressed = true;

                setTimeout(() => {
                    this.isCtrlPressed = false;
                }, 250);
            }

            if (e.keyCode == 8)
                return;

            let isNumber = /^\d+$/.test(e.key);

            if (isNumber && e.target.value.length < 10) {
                e.target.value += e.key;

                switch (e.target.value.length) {
                    case 2:
                    case 5:
                        e.target.value += '.';
                        break;

                    default:
                        break;
                }

                if (e.target.value.length == 10) {
                    let date = this.$moment(e.target.value, 'DD.MM.YYYY', true);

                    if (date.isValid())
                        this.filterFio.birthDateFrom = date.format('YYYY-MM-DD');
                }
            }
            
            e.preventDefault();
        },
        onPasteBirthDateFrom(e) {
            e.stopPropagation();
            e.preventDefault();

            let clipboardData = e.clipboardData || window.clipboardData;
            let pastedData = clipboardData.getData('Text');

            let date = this.$moment(pastedData, ['DD.MM.YYYY', 'D.MM.YYYY', 'D.M.YYYY', 'DD.MM.YY', 'D.MM.YY', 'D.M.YY'], true);

            if (date.isValid()) {
                this.filterFio.birthDateFrom = date.format('YYYY-MM-DD');
                this.dateMenuVisibleDateBirthFrom = true;
                return;
            }
            else {
                this.$notify.warning(this.$t("Данные_в_буфере_невалидны_вставка_невозможна."));
            }
        }
    },
    watch: {
        options: {
            handler () {
                if (this.total > 0)
                    this.loadVoters();
            },
            deep: true,
        }
    },
    filters: {
        formattedDate: function (value) {
            return sys.dateFormat(value, 'DD.MM.YYYY') ?? "";
        }
    },
    mounted() {
        if (this.$store.getters['saylau/lists/localSearch/isLoadWhenMounted']) {
            this.loadVoters();
            this.$store.commit('saylau/lists/localSearch/SET_LOAD_WHEN_MOUNTED', false);
        }
    }
}
</script>